<template>
    <div class="regulatory-news">
        <!-- begin: RU version -->
        <z-tabs v-if="dataRU && $root.lang === 'ru'">
            <template v-slot:label>
                <z-tabs-label
                    v-for="(item, index) in years"
                    @click.native="reset()"
                    :id="`${item.year}`"
                    :key="index"
                >{{ item.year }}</z-tabs-label>
                <z-tabs-label
                    v-if="archives.length"
                    id="regulatory-news-archive"
                >{{ text.archive }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="(item, index) in years"
                    :key="index"
                    :id="`${item.year}`"
                >
                    <card-list
                        v-for="(card, ind) in item.items.slice(0, count)"
                        :key="ind"
                        :data="card"
                        fix-time-zone
                    />
                    <z-button
                        v-if="item.items.length >= count"
                        @click.native="update()"
                        kind="spoiler"
                    >{{ text.more }}</z-button>
                </z-tabs-content>
                <z-tabs-content
                    v-if="archives.length"
                    id="regulatory-news-archive"
                >
                    <z-accordion
                        initial="first"
                        @toggle-item="reset()"
                    >
                        <z-accordion-item
                            v-for="(item, index) in archives"
                            :id="`${item.year}`"
                            :key="index"
                        >
                            <template v-slot:icon>
                                <z-icon name="slider" dir="down" height="10"></z-icon>
                            </template>
                            <template v-slot:header>{{ item.year }}</template>
                            <template v-slot:body>
                                <card-list
                                    v-for="(card, ind) in item.items.slice(0, count)"
                                    :key="ind"
                                    :data="card"
                                />
                                <z-button
                                    v-if="item.items.length >= count"
                                    @click.native="update()"
                                    kind="spoiler"
                                >{{ text.more }}</z-button>
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                </z-tabs-content>
            </template>
        </z-tabs>
        <!-- end: RU version -->

        <!-- begin: EN version -->
        <z-tabs v-if="dataEN && $root.lang === 'en'">
            <template v-slot:label>
                <!-- <z-tabs-label id="RNS">RNS</z-tabs-label> -->
                <z-tabs-label id="Disclosure">Disclosure</z-tabs-label>
            </template>
            <template v-slot:content>
                <!-- <z-tabs-content id="RNS">
                    <euroland-iframe
                        src="https://tools.eurolandir.com/tools/pressreleases/?companycode=ru-mgnt&lang=en-GB"
                        id="euroland-frame-1"
                    ></euroland-iframe>
                </z-tabs-content> -->
                <z-tabs-content id="Disclosure">
                    <z-table
                        :columns="columns"
                        responsive
                    >
                        <z-table-row
                            v-for="(card, index) in dataEN"
                            :key="index"
                        >
                            <z-table-col index="0">{{ card.name }}</z-table-col>
                            <z-table-col index="1">{{ card.date | momentFilter('DD MMMM YYYY') }}</z-table-col>
                            <z-table-col index="2">
                                <z-link
                                    :href="card.link"
                                    :download="card.name"
                                >
                                    <z-icon name="pdf" dir="top" width="32" height="32"></z-icon>
                                </z-link>
                            </z-table-col>
                        </z-table-row>
                    </z-table>
                </z-tabs-content>
            </template>
        </z-tabs>
        <!-- end: EN version -->
    </div>
</template>

<script>
export default {
    name: 'regulatory-news',
    data () {
        return {
            dataRU: this.$root.app.components['essential-facts'],
            dataEN: this.$root.app.components['essential-facts-en'],
            columns: ['Title', 'Publication Date', 'File'],
            text: {
                archive: this.$root.lang === 'ru' ? 'Архив' : 'Archive',
                more: this.$root.lang === 'ru' ? 'Показать еще' : 'Show more'
            },
            step: 10,
            count: 10,
            years: [],
            archives: [],
            loading: false
        }
    },
    created () {
        if (this.$root.lang === 'en') return

        this.years = this.getList(this.dataRU.years)
        this.archives = this.getList(this.dataRU.archive)
    },
    methods: {
        getList (items) {
            let list = Object.keys(items).reverse()
            let years = []

            for (let i = 0; i < list.length; ++i) {
                var key = list[i]
                years.push({
                    year: key,
                    items: items[key]
                })
            }

            return years
        },
        reset () {
            this.count = this.step
        },
        update () {
            this.count = this.count + this.step
        }
    }
}
</script>
